import React from "react";
import aboutImg1 from "../../../assets/images/about/ab_img.png";
import aboutImg2 from "../../../assets/images/about/ab_img1.png";
import aboutImg3 from "../../../assets/images/about/ab_img2.png";

const AboutTeam = () => {
  return (
    <>
      <section>
        <div className="about-team-pages-area sc-pt-130 sc-md-pt-70 sc-pb-180 sc-md-pb-80">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 p-z-idex">
                <div className="sc-heading-area sc-mb-105 sc-md-mb-60 text-center">
                  <span className="sc-sub-title primary-color">
                    About The Journey
                  </span>
                  <h2 className="heading-title sc-mb-25">
                    We Help Small Businesses With Big Hearts Make Meaningful
                    Hires.
                  </h2>
                  <div className="description sc-mb-20">
                    Our mission is to empower small businesses by connecting
                    them with the right talent through personalized solutions
                    and innovative recruitment services. We understand the
                    unique challenges small businesses face and are dedicated to
                    supporting their growth by building strong, effective teams.
                    Join us in our mission to make meaningful connections and
                    help small businesses achieve big impacts.
                  </div>
                </div>
              </div>
            </div>
            <div className="row position-relative">
              <div className="col-lg-4 p-z-idex">
                <div className="about_team_Image text-center sc-md-mb-30">
                  <img src={aboutImg1} alt="About" />
                </div>
              </div>
              <div className="col-lg-4 p-z-idex">
                <div className="about_team_Image text-center sc-md-mb-30">
                  <img src={aboutImg2} alt="About" />
                </div>
              </div>
              <div className="col-lg-4 p-z-idex">
                <div className="about_team_Image text-center">
                  <img src={aboutImg3} alt="About" />
                </div>
              </div>
              <div className="sc-ab-circle purple"></div>
              <div className="sc-ab-circle yelow"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutTeam;
