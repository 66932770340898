import React from "react";

const FaqQuestion = () => {
  return (
    <>
      <div className="sc-faq-pages-area sc-pt-130 sc-md-pt-70 sc-pb-10 sc-md-pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="sc-heading text-center sc-mb-60">
                <h2 className="sc-mb-20">Frequently Asked Questions</h2>
                <div className="description">
                  Our FAQ page provides answers to some of the most commonly
                  asked questions about our services and processes.
                </div>
              </div>
            </div>
            <div className="col-lg-8 offset-lg-2">
              <div className="accordion sc-faq-style" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      How do I manage construction requirements?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>
                        To manage construction requirements effectively, it's
                        important to follow a structured approach that includes
                        defining the scope, setting objectives, and using
                        project management tools to track progress. Regular
                        communication with stakeholders and ensuring compliance
                        with local regulations is also crucial.
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How do I utilize virtual design and build?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>
                        Virtual design and build involve using digital tools and
                        software to create detailed models of construction
                        projects before actual construction begins. This
                        approach helps in identifying potential issues,
                        optimizing designs, and improving collaboration among
                        teams.
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How do I manage project requirements?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>
                        Managing project requirements involves gathering
                        detailed specifications from stakeholders, prioritizing
                        tasks, and ensuring that all requirements are documented
                        and tracked throughout the project lifecycle. Utilizing
                        project management software can streamline this process.
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      What is the effective staking amount?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>
                        The effective staking amount refers to the optimal
                        amount of resources allocated to a task or project to
                        achieve the best results. This varies depending on the
                        project's scope, complexity, and available resources.
                        Proper planning and analysis are necessary to determine
                        the effective staking amount for your specific needs.
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqQuestion;
